<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <label for="" class="form-label">Filtrar</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="busqueda.filtro"
              />
            </div>
            <div class="col-md-2">
              <button
                class="btn btn-sm btn-primary mt-9"
                type="button"
                @click="handleFiltrar"
                id="btn_buscar"
              >
                <span class="indicator-label">Buscar</span>
                <span class="indicator-progress"
                  >Buscando...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-sm table-bordered g-2">
            <thead>
              <tr class="border table-active">
                <th class="text-center">#</th>
                <th>Proyecto</th>
                <th>Cliente</th>
                <th>Elementos Requeridos</th>
                <th>Elementos Contratados</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="border" v-for="item in proyectos" :key="item.id">
                <td class="text-center">{{ item.id }}</td>
                <td>{{ item.nombre }}</td>
                <td>{{ item.cliente }}</td>
                <td>{{ item.elementos }}</td>
                <td>{{ item.contratados }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-sm btn-xs btn-light-primary mx-2"
                    @click="
                      () => {
                        handleEditar(item.id);
                      }
                    "
                  >
                    <i class="bi bi-pencil"></i>
                  </button>

                  <button
                    type="button"
                    class="btn btn-sm btn-xs btn-light-danger"
                    @click="
                      () => {
                        handleEliminar(item.id);
                      }
                    "
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="border"></tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import useProyectos from "../composables/useProyectos";
export default {
  setup() {
    const router = useRouter();
    const {
      loading,
      proyectos,
      busqueda,
      getProyectos,
      busquedaIni,
      setEliminarProyecto,
    } = useProyectos();

    const handleFiltrar = () => {
      getProyectos(busqueda.value);
    };

    const handleEditar = (id) => {
      return router.push({ name: "proyectos-editar", params: { cid: id } });
    };

    const handleAgregar = () => {
      return router.push({ name: "proyectos-alta" });
    };

    const handleEliminar = (id) => {
      Swal.fire({
        title: "Confirmar operación",
        text: "¿Desea eliminar el proyecto?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.isConfirmed) {
          setEliminarProyecto(id).then((result) => {
            if (result.ok) {
              Swal.fire("Eliminado", "Se ha eliminado el proyecto", "success");
              getProyectos(busqueda.value);
              return router.replace({ name: "proyectos" });
            } else {
              Swal.fire("Error", result.message, "error");
            }
          });
        }
      });
    };

    watch(
      () => loading.value,
      () => {
        var btnLogin = document.querySelector("#btn_buscar");
        if (loading.value) {
          btnLogin.setAttribute("data-kt-indicator", "on");
          btnLogin.setAttribute("disabled", true);
        } else {
          btnLogin.setAttribute("data-kt-indicator", "off");
          btnLogin.setAttribute("disabled", null);
          btnLogin.removeAttribute("disabled");
        }
      }
    );

    return {
      proyectos,
      busqueda,
      handleFiltrar,
      handleEditar,
      handleAgregar,
      handleEliminar,
    };
  },
};
</script>

<style scoped>
</style>